
import { Vue, Component } from 'vue-property-decorator'
import { ITerminalOptions, Terminal } from 'xterm'
import { FitAddon } from 'xterm-addon-fit'
import 'xterm/css/xterm.css'
import FileSaver from 'file-saver'
import toggleFullScreen from '../fullscreen-utils'

/**
 * 控制台接口
 */
export interface XtermVueInterface{
  // 写入消息
  addMessage (message?: string): void;
  // 写入一行消息
  addLineMessage(message?: string): void;
  //  清空消息
  clear (): void;
}

/**
 * Xterm控制台效果的组件
 */
@Component({
  name: 'xtermVue'
})
export default class XtermVue extends Vue implements XtermVueInterface {
    // 控制台实例
    terminal?: Terminal
    fitAddon?: FitAddon

    contextH=0

    fulled=false

    rows=0
    columns=0;

    terminalOption: ITerminalOptions = {
      lineHeight: 1.0,
      disableStdin: true,
      // scrollback: 25,
      fastScrollModifier: 'alt',
      // fontSize: 14,
      cursorStyle: 'underline',
      logLevel: 'warn',
      screenReaderMode: true

      // fontFamily: "'Cascadia Code',monospace"
    }

    /// 页面加载事件
    mounted () {
      this.terminal = new Terminal(this.terminalOption)
      this.fitAddon = new FitAddon()
      this.terminal.loadAddon(this.fitAddon)

      this.terminal.open((this.$refs.body as HTMLElement))
      this.$nextTick(() => {
        this.fitAddon?.fit()
      })
      this.$emit('ready')
      const that = this
      window.onresize = () => {
        that.fitAddon?.fit()
      }
      document.onfullscreenchange = () => {
        if (this.fulled) {
          that.fitAddon?.fit()
          this.fulled = false
        }
      }
    }

    /**
     * 向控制台添加消息
     */
    public addMessage (message?: string) {
      if (message) {
            this.terminal?.write(message)
      } else {
            this.terminal?.write(' ')
      }
    }

    /**
     * 向控制台添加一行消息
     * @param message
     */
    public addLineMessage (message?: string) {
      if (message) {
            this.terminal?.writeln(message)
      } else {
            this.terminal?.writeln(' ')
      }
    }

    /**
   * 导出日志为txt文件
   */
    exportLogToTxt () {
      this.terminal?.selectAll()
      const strs = this.terminal?.getSelection()
      if (strs && strs.match(/\S/)) {
        const blob = new Blob([strs], { type: 'text/plain;charset=utf-8' })
        FileSaver.saveAs(blob, 'codeGeneLog.txt')
      } else {
        this.$Message.error('终端暂无可导出日志')
      }
      this.terminal?.clearSelection()
    }

    /**
     * 清除内容
     */
    public clear () {
      this.terminal?.clear()
    }

    /**
     * 切换全屏
     */
    toggle () {
      toggleFullScreen((this.$refs.xtermContext as HTMLDivElement))
        .then((res) => {
          this.fulled = res
          if (!this.fulled) this.terminal?.resize(this.columns, this.rows)
          this.fitAddon?.fit()
        })
        .catch((ex) => this.$Message.error('切换全展失败:' + ex.message))
    }
}
