import { expect } from 'chai'
import Fullscreen from 'fullscrn'

/**
 * 全屏切换 新版本的全屏工具
 * @param elId 要切换的html组件id
 * @returns 切换后对应的全屏状态，如果为true处理全屏中
 */
export default async function toggleFullScreen (elOrId: string | HTMLElement): Promise<boolean> {
  expect(elOrId, '全屏组件id不允许为空').not.undefined

  const grid = elOrId instanceof Object ? elOrId : document.getElementById(elOrId as string)

  // 如果当前是全屏，则退出
  if (!document.fullscreenElement && grid) {
    await Fullscreen.request(grid)
    return true
  } else {
    await Fullscreen.exit()
    return false
  }
}
